<template>
  <v-container>
    <v-row>
      <v-col class="d-none d-lg-flex flex-column" cols="6">
        <div class="primary justify-space-between" style="height: 800px; max-width: 500px">
          <div class="pt-16">
            <v-row class="justify-center text-center">
              <v-col>
                <h1 style="color: white">
                  Verbreiten Sie die Idee, <br />
                  nicht das Virus!
                </h1>
              </v-col>
            </v-row>
          </div>
          <v-img src="../assets/CORONA_TEST_POINT_BG_IMAGE_2000PX_RGB.png" height="633" contain> </v-img>
        </div>
      </v-col>
      <v-col class="d-flex justify-center text-center align-center">
        <v-form v-if="step == 'login'" ref="form" v-model="valid" lazy-validation>
          <h2 class="headline font-weight-bold mb-1">
            Bitte melden Sie <br />
            sich hier mit Ihrer E-Mail <br />
            Adresse an.
          </h2>
          <div v-if="error != ''" class="red--text">{{ error }}</div>
          <v-text-field ref="mail" v-model="formData.mail" :rules="[() => !!formData.mail || 'Dieses Feld wird benötigt!']" required label="E-Mail Adresse" class="mt-5"></v-text-field>
          <v-text-field
            v-model="formData.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            label="Passwort"
            @click:append="showPassword = !showPassword"
            required
          ></v-text-field>
          <v-btn :disabled="!valid" block x-large color="primary" class="mt-5" @click="submit">
            Anmelden
          </v-btn>
          <v-btn :disabled="!valid" block x-large outlined color="primary" class="mt-2" @click="$router.push('/registration')">
            Registrieren
          </v-btn>
          <v-btn :disabled="!valid" block x-large outlined color="primary" class="mt-2" @click="$router.push('/booking')">
            Test buchen
          </v-btn>
          <p class="mt-3">
            <a @click="step = 'password'"> Passwort vergessen? </a>
          </p>
        </v-form>
        <v-form v-if="step == 'password'" ref="pwForm" v-model="pwForm" lazy-validation>
          <h2 class="headline font-weight-bold mb-1">
            Bitte geben Sie <br />
            Ihre E-Mail Adresse ein
          </h2>
          <div v-if="error != ''" class="red--text">{{ error }}</div>
          <v-text-field ref="mail" v-model="pwData.mail" :rules="[() => !!pwData.mail || 'Dieses Feld wird benötigt!']" required label="E-Mail Adresse" class="mt-5"></v-text-field>
          <v-btn :disabled="!valid" block x-large color="primary" class="mt-5" @click="resetPassword">
            Passwort zurücksetzen
          </v-btn>
          <p class="mt-3"><a @click="step = 'login'">Zurück zum Login</a></p>
        </v-form>
        <div v-if="step == 'reset'">Sie erhalten in kürze eine E-Mail mit den <br />Instruktionen zum Zurücksetzen ihres Passwortes.</div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    pwForm: false,
    showPassword: false,
    step: "login",
    snackbar: false,
    snackbarText: "",
    error: "",
    formData: {
      mail: "",
      password: "",
    },
    pwData: {},
    rules: {
      required: (value) => !!value || "Dieses Feld wird benötigt!",
    },
  }),

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        let email = this.formData.mail;
        let password = this.formData.password;

        this.$http({
          method: "put",
          url: "/entrance/login",
          data: {
            emailAddress: email,
            password: password,
          },
        })
          .then((response) => {
            this.$store
              .dispatch("login", response.data)
              .then(() => this.$router.push("/"))
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.error = "E-Mail oder Passwort stimmen nicht überein.";
              this.snackbarText = "Die E-Mail Adresse oder das Passwort stimmen nicht überein";
            } else {
              this.snackbarText = "Fehler bei Abfrage";
            }
            this.snackbar = true;
            console.log("AXIOS ERROR: ", err);
          });
      }
    },

    resetPassword() {
      if (this.$refs.pwForm.validate()) {
        this.$http({
          method: "post",
          url: "/entrance/send-password-recovery-email",
          data: {
            emailAddress: this.pwData.mail,
          },
        })
          .then(() => {
            this.step = "reset";
            this.snackbarText = "Passwort reset E-mail erfolgreich versendet";
            this.snackbar = true;
          })
          .catch((err) => {
            this.snackbarText = "Fehler bei Abfrage";
            this.snackbar = true;
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>
